@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	h1,
	h2,
	h3,
	h4,
	p,
	nav a {
		@apply text-brown-50;
	}

	h1,
	h2,
	h3,
	h4 {
		@apply font-headings;
	}

	p {
		@apply font-body my-2 block;
	}

	h1 {
		@apply text-sub md:text-section lg:text-page font-bold leading-none;
	}

	h2 {
		@apply md:text-sub lg:text-section font-bold leading-none;
	}

	h3 {
		@apply text-sub font-bold leading-none;
	}

	h4 {
		@apply text-subSection font-semibold leading-none;
	}

	/* Button styling */
	.button {
		@apply flex gap-3 justify-center items-center py-3 px-5 font-body text-sm whitespace-nowrap md:text-base tracking-widest transition;
	}

	.primary {
		@apply bg-current bg-green-100 hover:bg-green-200 text-beige-300;
	}

	.secondary {
		@apply bg-current bg-beige-100;
	}

	.primary-button-icon {
		@apply fill-current text-beige-300;
	}

	.secondary-button-icon {
		@apply fill-current text-brown-50;
	}

	/* Background image size and placement */
	.sm-bg-cover {
		background-position: -240px -530px !important;
		background-size: 1130px !important;
		background-repeat: no-repeat !important;
	}

	.md-bg-cover {
		background-position: -50px -970px !important;
		background-size: 1800px !important;
		background-repeat: no-repeat !important;
	}

	.lg-bg-cover {
		background-position: -30px -1030px !important;
		background-size: 2170px !important;
		background-repeat: no-repeat !important;
	}

	.xl-bg-cover {
		background-position: 30px -1630px !important;
		background-size: 2870px !important;
		background-repeat: no-repeat !important;
	}

	.bg-cover {
		background-position: center !important;
		background-size: cover !important;
		background-repeat: no-repeat !important;
	}

	/* Template styles */
	.include-footer {
		@apply col-start-1 lg:col-start-2 col-end-7 lg:col-end-9 xl:pl-0 lg:pl-20 relative mt-20 grid grid-cols-8;
	}

	.page-template {
		@apply w-full m-auto mb-32 mt-6 col-start-2 col-end-8;
	}

	.gallerij-content-template {
		@apply xl:w-3/12;
	}

	.gallerij-main-template {
		@apply mt-10 flex flex-col xl:flex-row gap-10;
	}

	.gallerij-slideshow-template {
		@apply xl:w-7/12;
	}

	/* Form elements */
	.input {
		@apply border border-brown-400 rounded p-2 transition w-full;
	}
}

.leaflet-container {
	width: 100%;
	height: 100%;
}

/* Swiper thumbnails */
.swiper-slide-thumb-active {
	opacity: 1 !important;
	transition: .3s !important;
}
